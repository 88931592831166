export default {
  white: {
    backgroundColor: 'neutral.lightest',
    titleColor: 'text.primary',
    bodyColor: 'text.secondary',
    iconColor: 'primary.main',
    trustPilotTheme: 'light',
  },
  ghost: {
    backgroundColor: 'neutral.lighter',
    titleColor: 'text.primary',
    bodyColor: 'text.primary',
    iconColor: 'primary.main',
    trustPilotTheme: 'light',
  },
  smoke: {
    backgroundColor: 'neutral.light',
    titleColor: 'text.primary',
    bodyColor: 'text.primary',
    iconColor: 'primary.main',
    trustPilotTheme: 'light',
  },
  periwinkle_dark: {
    backgroundColor: 'text.primary',
    titleColor: 'primary.main',
    bodyColor: 'neutral.lightest',
    iconColor: 'primary.main',
    trustPilotTheme: 'dark',
  },
  periwinkle_main: {
    backgroundColor: 'secondary.main',
    titleColor: 'neutral.lightest',
    bodyColor: 'neutral.lightest',
    iconColor: 'neutral.lightest',
    trustPilotTheme: 'dark',
  },
  periwinkle_light: {
    backgroundColor: 'secondary.light',
    titleColor: 'text.primary',
    bodyColor: 'text.primary',
    iconColor: 'text.primary',
    trustPilotTheme: 'light',
  },
};
