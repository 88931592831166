/* eslint-disable react/prop-types */
import React from 'react';
import { arrayOf, number, oneOf, shape, string } from 'prop-types';
import GraphImage from '@graphcms/react-image';
import { Box, Typography } from '@mui/material';
import { RichText } from '@graphcms/rich-text-react-renderer';
import Button from '@/components/Button';
import { BUTTON_TYPE } from '@/types';
import Link from '../../Link';

const HalvesBlockV2 = ({
  id,
  alignment,
  title,
  description: { raw },
  buttons,
  image: { handle, alt, height, width },
  theme: { bodyColor, titleColor },
}) => {
  const left = alignment === 'left';
  return (
    <Box
      display="flex"
      flexDirection={{
        xs: 'column',
        md: left ? 'row' : 'row-reverse',
      }}
      alignItems="center"
      height="fit-content"
    >
      <Box
        width={{
          xs: '100%',
          md: '50%',
        }}
        maxWidth={550}
        position="relative"
      >
        <GraphImage
          image={{
            handle,
            width,
            height,
          }}
          alt={alt}
          withWebp
          blurryPlaceholder={false}
        />
      </Box>
      <Box
        flexGrow={{ md: 1 }}
        pt={{ xs: 6, md: 0 }}
        pr={{
          md: left ? 0 : 4,
          lg: left ? 0 : 10,
        }}
        pl={{
          md: left ? 4 : 0,
          lg: left ? 10 : 0,
        }}
        width={{
          xs: '100%',
          md: '50%',
        }}
      >
        <Typography variant="h3" component="h3" color={titleColor} width={{ lg: 500 }}>
          {title}
        </Typography>
        <RichText
          content={raw.children}
          renderers={{
            p: ({ children }) => (
              <Typography my={1} mx={0} color={bodyColor} variant="body2" width={{ lg: 500 }}>
                {children}
              </Typography>
            ),
            ol: ({ children }) => (
              <Typography my={1} mx={0} color={bodyColor} variant="body2" width={{ lg: 500 }}>
                {children}
              </Typography>
            ),
            ul: ({ children }) => (
              <Typography my={1} mx={0} color={bodyColor} variant="body2" width={{ lg: 500 }}>
                {children}
              </Typography>
            ),
            a: ({ children, href }) => <Link href={href}>{children}</Link>,
          }}
        />

        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          m={{ xs: 'auto', sm: 0 }}
          pt={{
            xs: buttons ? 2 : 0,
            md: buttons ? 5 : 0,
          }}
          alignItems={{
            xs: buttons ? 'center' : 'start',
            sm: 'start',
          }}
        >
          {buttons.map(({ id: buttonId, label, href, color, variant }, i) => (
            <Box
              key={`${id}_${buttonId}`} /* uid of block + uid of button for unique key */
              width={{
                xs: '100%',
                md: 'auto',
              }}
              flexGrow={1}
              maxWidth={{ md: '250px' }}
              mb={{ xs: i + 1 !== buttons.length ? 2 : 0, md: 1 }}
              mr={{ md: 2 }}
            >
              <Button variant={variant} color={color} href={href} py={4} sx={{ 'border-radius': 0 }}>
                {label}
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

HalvesBlockV2.propTypes = {
  /* Generate uid of block from graphcms */
  id: string.isRequired,
  /* Text form of alignment. Expected left or right */
  alignment: oneOf(['left', 'right']).isRequired,
  title: string.isRequired,
  description: shape({ raw: { children: [] } }).isRequired,
  image: shape({
    handle: string.isRequired,
    alt: string.isRequired,
    height: number.isRequired,
    width: number.isRequired,
  }).isRequired,
  /* Array of buttons. One object is one button */
  buttons: arrayOf(BUTTON_TYPE).isRequired,
  theme: shape({
    backgroundColor: string.isRequired,
    titleColor: string.isRequired,
    bodyColor: string.isRequired,
  }).isRequired,
};

export default HalvesBlockV2;
