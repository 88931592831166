import React, { forwardRef } from 'react';
import { node, string } from 'prop-types';
import { Link as MuiLink } from '@mui/material';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import _ from 'lodash';

const Link = forwardRef(({ href, children, ...props }, ref) => {
  const router = useRouter();
  const internal = href.startsWith('/');
  const tel = href.startsWith('tel:');
  const filteredQuery = Object.keys(router.query)
    .filter(
      key =>
        !key.includes('slug') &&
        !key.includes('city') &&
        !key.includes('cityGenSlug') &&
        !key.includes('state') &&
        !key.includes('serviceGenPage'),
    )
    .reduce(
      (obj, key) =>
        Object.assign(obj, {
          [key]: router.query[key],
        }),
      {},
    );
  const query = _.isEmpty(filteredQuery) ? '' : `?${queryString.stringify(filteredQuery)}`;

  return !internal ? (
    <MuiLink
      ref={ref}
      href={`${href}${typeof window !== 'undefined' ? window.location.search : ''}`}
      underline="none"
      target={tel ? '_self' : '_blank'}
      rel="noreferrer"
      {...props}
    >
      {children}
    </MuiLink>
  ) : (
    <MuiLink ref={ref} href={`${href}${query ?? ''}`} underline="none" {...props}>
      {children}
    </MuiLink>
  );
});

Link.propTypes = {
  href: string.isRequired,
  children: node.isRequired,
};

export default Link;
