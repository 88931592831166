import React from 'react';
import { bool, oneOf, shape, string } from 'prop-types';
import { Box, Typography } from '@mui/material';

import { TEXT_TYPE } from '@/types';
import themes from '../../theme/helpers/blockThemes';
import * as allBlocks from '../Blocks';

const BlockWrapper = ({
  theme,
  heading,
  subHeading,
  overrideBottomPadding,
  overrideTopPadding,
  components,
  block,
  stateData,
  cityData,
  neighborhoodData,
}) => {
  const blockTheme = themes[theme];
  const Component = allBlocks[block?.__typename];

  return (
    <Box
      // py={{ xs: 9, md: 12 }}
      pt={{
        xs: overrideTopPadding ? 0 : 9,
        md: overrideTopPadding ? 0 : 12,
      }}
      pb={{
        xs: overrideBottomPadding ? 0 : 9,
        md: overrideBottomPadding ? 0 : 12,
      }}
      bgcolor={blockTheme.backgroundColor}
    >
      <Box
        maxWidth={1440}
        mx="auto"
        px={{
          xs: 3,
          sm: 7,
          md: 10,
          xl: 15,
        }}
      >
        {(heading || subHeading) && (
          <Box mx="auto" pb={{ xs: block ? 3 : 0, md: block ? 8 : 0 }} textAlign="center">
            {heading && (
              <Typography
                color={blockTheme.titleColor}
                variant={heading.variant}
                component={heading.component}
                align={heading.alignment}
                pb={subHeading ? 2 : 0}
              >
                {heading.text}
              </Typography>
            )}
            {subHeading && (
              <Box maxWidth={600} mx="auto">
                <Typography
                  color={blockTheme.bodyColor}
                  variant={subHeading.variant}
                  component={subHeading.component}
                  align={subHeading.alignment}
                >
                  {subHeading.text}
                </Typography>
              </Box>
            )}
          </Box>
        )}
        {Component && (
          <Component
            {...block}
            theme={blockTheme}
            components={components}
            stateData={stateData}
            cityData={cityData}
            neighborhoodData={neighborhoodData}
          />
        )}
      </Box>
    </Box>
  );
};

BlockWrapper.propTypes = {
  theme: oneOf(['white', 'ghost', 'smoke', 'periwinkle_dark', 'periwinkle_light', 'periwinkle_main']).isRequired,
  heading: TEXT_TYPE,
  subHeading: TEXT_TYPE,
  overrideTopPadding: bool.isRequired,
  overrideBottomPadding: bool.isRequired,
  block: shape({
    __typename: string.isRequired,
  }),
  components: shape({}),
  stateData: shape({}),
  cityData: shape({}),
  neighborhoodData: shape({}),
};

BlockWrapper.defaultProps = {
  block: null,
  heading: null,
  subHeading: null,
  components: null,
  stateData: null,
  cityData: null,
  neighborhoodData: null,
};

export default BlockWrapper;
