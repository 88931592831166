import React from 'react';
import GraphImage from '@graphcms/react-image';
import { Box, Typography } from '@mui/material';
import { arrayOf, string, shape, oneOf, number } from 'prop-types';

import { LINK_TYPE } from '@/types';
import Link from '../../Link';

const ThirdsBlock = ({ align, columns, theme: { bodyColor, titleColor } }) => (
  <Box
    display="flex"
    justifyContent="space-evenly"
    flexDirection={{ xs: 'column', md: 'row' }}
    alignItems={{ xs: 'center', md: 'baseline' }}
  >
    {columns.map(
      ({ title, description, link, image }, i) =>
        i < 3 && (
          <Box
            key={title}
            flexGrow={1}
            flexBasis={1 / 3}
            maxWidth={300}
            display="flex"
            flexDirection="column"
            alignItems={align}
            mb={{ xs: i < 2 ? 4 : 0, md: 0 }}
            mx={{ md: 1 }}
          >
            {image && (
              <Box maxWidth={75}>
                {image.mimeType === 'image/svg+xml' ? (
                  <img src={image.url} alt={image.alt} width={75} height={75} loading="lazy" />
                ) : (
                  <GraphImage
                    image={{
                      handle: image.handle,
                      width: image.width,
                      height: image.height,
                    }}
                    alt={image.alt}
                    withWebp
                    blurryPlaceholder={false}
                  />
                )}
              </Box>
            )}
            <Typography variant="h4" pb={1} color={titleColor} align={align}>
              {title}
            </Typography>
            <Typography variant="body2" color={bodyColor} align={align}>
              {description}
            </Typography>
            {link && (
              <Link href={link.href} variant="body2" fontFamily="h1.fontFamily" pt={1}>
                {link.label}
              </Link>
            )}
          </Box>
        ),
    )}
  </Box>
);

ThirdsBlock.propTypes = {
  align: oneOf(['left', 'center']).isRequired,
  columns: arrayOf(
    shape({
      title: string.isRequired,
      description: string.isRequired,
      link: LINK_TYPE,
      image: shape({
        alt: string.isRequired,
        url: string.isRequired,
        handle: string.isRequired,
        mimeType: string.isRequired,
        width: number.isRequired,
        height: number.isRequired,
      }),
    }),
  ).isRequired,
  theme: shape({
    backgroundColor: string.isRequired,
    titleColor: string.isRequired,
    bodyColor: string.isRequired,
  }).isRequired,
};

export default ThirdsBlock;
