import React from 'react';
import { string, number, shape, oneOfType } from 'prop-types';
import { Box, Typography, Rating } from '@mui/material';

const QuoteBlock = ({ quote, author, rating, theme: { bodyColor } }) => (
  <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
    <Box pb={1}>
      <img height={40} width={60} src="/images/quote.svg" alt="quotes" loading="lazy" />
    </Box>

    <Box maxWidth={750}>
      <Typography variant="body1" color={bodyColor}>
        {quote}
      </Typography>
    </Box>

    <Box pt={2}>
      <Typography variant="body2" color="neutral.light" fontStyle="italic">
        {`— ${author}`}
      </Typography>
    </Box>

    <Box mt={2}>
      <Rating sx={{ color: '#FA6303' }} value={Number(rating)} readOnly />
    </Box>
  </Box>
);

QuoteBlock.propTypes = {
  quote: string.isRequired,
  author: string.isRequired,
  rating: oneOfType([number, string]).isRequired,
  theme: shape({
    titleColor: string.isRequired,
  }).isRequired,
};

export default QuoteBlock;
