/* eslint-disable react/prop-types */
import React from 'react';
import { string, shape } from 'prop-types';
import { MDXRemote } from 'next-mdx-remote';
import { Typography, Box } from '@mui/material';
import Link from '../Link';

const Markdown = ({ source, theme: { titleColor, bodyColor }, components: customComponents }) => {
  const components = {
    h1: props => <Typography gutterBottom variant="h1" color={titleColor} {...props} />,
    h2: props => <Typography gutterBottom variant="h2" sx={{ pb: 1, pt: 2 }} pb={1} pt={2} color={titleColor} {...props} />,
    h3: props => <Typography sx={{ py: 1 }} gutterBottom variant="h3" color={titleColor} {...props} />,
    h4: props => <Typography sx={{ py: 1 }} gutterBottom variant="h4" color={titleColor} {...props} />,
    p: ({ ...props }) => (
      <Typography sx={{ '&:not(:last-of-type)': { mb: 1 } }} variant="body2" color={bodyColor} {...props} />
    ),
    li: props => <Typography variant="body2" component="li" color={bodyColor} {...props} />,
    a: props => <Link {...props} />,
    img: props => {
      const { src, alt } = props;
      return (
        <Box sx={{ width: 'auto', mb: 3 }}>
          <img src={src} alt={alt} width="100%" loading="lazy" />
        </Box>
      );
    },
  };

  return <MDXRemote {...source} components={{ ...components, ...customComponents }} />;
};

Markdown.propTypes = {
  source: shape({}).isRequired,
  theme: shape({
    titleColor: string,
    bodyColor: string,
  }).isRequired,
  components: shape({}),
};

Markdown.defaultProps = {
  components: null,
};

export default Markdown;
